export const DepartmentCompHeader = [
  { field: 'serialNo', headerName: 'S.NO' },
  { field: 'department_name', headerName: 'DEPARTMENTS' },
  { field: 'department_head_name', headerName: 'DEPARTMENTS HEAD' },
  { field: 'createdAt', headerName: 'CREATED DATE' },
  { field: 'created_By_name', headerName: 'CREATED BY' },
  { field: 'updatedAt', headerName: 'UPDATED DATE' },
  { field: 'modified_By_Name', headerName: 'UPDATED BY' },
  { field: 'status', headerName: 'STATUS' },
];
export const DesignationcompHeader = [
  { field: 'serialNo', headerName: 'S.NO' },
  { field: 'designation_name', headerName: 'DESIGNATIONS' },
  { field: 'createdAt', headerName: 'CREATED DATE' },
  { field: 'created_By_name', headerName: 'CREATED BY' },
  { field: 'updatedAt', headerName: 'UPDATED DATE' },
  { field: 'modified_By_Name', headerName: 'UPDATED BY' },
  { field: 'status', headerName: 'STATUS' },
];
export const emailComponentHeader = [
  { field: 'serialNo', headerName: 'S.NO' },
  { field: 'title', headerName: 'TITLE' },
  { field: 'subject', headerName: 'SUBJECT' },
  { field: 'department_name', headerName: 'DEPARTMENTS' },
  { field: 'createdAt', headerName: 'CREATED DATE' },
  { field: 'status', headerName: 'STATUS' },
];
export const leaveCompHeader = [
  { field: 'serialNo', headerName: 'S.NO' },
  { field: 'holiday_name', headerName: 'PUBLIC HOLIDAYS' },
  { field: 'date_of_holiday_table', headerName: 'DATE' },
  { field: 'createdAt', headerName: 'CREATED DATE' },
  { field: 'created_By_name', headerName: 'CREATED BY' },
  { field: 'updatedAt', headerName: 'UPDATED DATE' },
  { field: 'modified_By_Name', headerName: 'UPDATED BY' },
];
export const myRequestLeaveHeaders = [
  // { field: "sno", headerName: "S.NO", className: "" },
  { field: 'serialNo', headerName: 'S.NO', className: '' },
  { field: 'type', headerName: 'TYPE', className: '' },
  { field: 'startDate', headerName: 'START DATE', className: '' },
  { field: 'endDate', headerName: 'END DATE', className: '' },
  { field: 'daycount', headerName: 'DAYS', className: '' },
  { field: 'reason', headerName: 'REASON', className: '' },
  { field: 'status', headerName: 'STATUS', className: '' },
];

export const userRequestPermissionHeaders = [
  { field: 'serialNo', headerName: 'S.NO', className: '' },
  { field: 'employee_name', headerName: 'EMPLOYEE NAME', className: '' },
  { field: 'type', headerName: 'TYPE', className: '' },
  { field: 'dateString', headerName: 'DATE', className: '' },
  { field: 'month', headerName: 'MONTH', className: '' },
  { field: 'year', headerName: 'YEAR', className: '' },
  { field: 'timeSpent', headerName: 'PERMISSION TIME', className: '' },
  { field: 'hourCount', headerName: 'TOTAL HOURS', className: '' },
  { field: 'reason', headerName: 'REASON', className: '' },
  { field: 'status', headerName: 'STATUS', className: '' },
];

export const myRequestPermissionHeaders = [
  { field: 'serialNo', headerName: 'S.NO', className: '' },
  { field: 'dateString', headerName: 'DATE', className: '' },
  { field: 'month', headerName: 'MONTH', className: '' },
  { field: 'year', headerName: 'YEAR', className: '' },
  { field: 'timeSpent', headerName: 'PERMISSION TIME', className: '' },
  { field: 'hourCount', headerName: 'TOTAL HOURS', className: '' },
  { field: 'reason', headerName: 'REASON', className: '' },
  { field: 'status', headerName: 'STATUS', className: '' },
];
export const userRequestsLeaveHeaders = [
  // { field: "sno", headerName: "S.NO", className: "" },
  { field: 'serialNo', headerName: 'S.NO', className: '' },
  { field: 'employee_name', headerName: 'EMPLOYEE NAME', className: '' },
  // { field: 'employee_code', headerName: 'EMPLOYEE CODE', className: '' },
  { field: 'type', headerName: 'TYPE', className: '' },
  { field: 'startDate', headerName: 'START DATE', className: '' },
  { field: 'endDate', headerName: 'END DATE', className: '' },
  { field: 'daycount', headerName: 'DAYS', className: '' },
  { field: 'reason', headerName: 'REASON', className: '' },
  { field: 'status', headerName: 'STATUS', className: '' },
  // { field: "action", headerName: "ACTION", className: "" },
];
export const PermissionsTaken = [
  // { field: "sno", headerName: "S.NO", className: "" },
  { field: 'serialNo', headerName: 'S.NO', className: '' },
  { field: 'employeeName', headerName: 'EMPLOYEE', className: '' },
  { field: 'date', headerName: 'DATE', className: '' },
];
export const EmployeesleaveTaken = [
  { field: 'serialNo', headerName: 'S.NO', className: '' },
  { field: 'employeeName', headerName: 'EMPLOYEE', className: '' },
  { field: 'date', headerName: ' DATE', className: '' },
  // { field: 'endDate', headerName: 'TO DATE', className: '' },
  // { field: 'dayCount', headerName: 'LEAVE COUNT', className: '' },
  { field: 'type', headerName: 'LEAVE TYPE', className: '' },
];

export const MY_PROJECT_TABLE = [
  { field: 'serialNo', headerName: 'S.NO', className: 'col-1' },
  { field: 'module_name', headerName: 'MODULE', className: 'col-2' },
  { field: 'project_name', headerName: 'PROJECT', className: 'col-2' },
  {
    field: 'estimated_hours',
    headerName: ' ESTIMATED HOURS',
    className: 'col-2',
  },
  { field: 'module_status', headerName: 'STATUS', className: 'col-2' },
];
