import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { Tabs, Card } from 'antd';
import style from '../../Styles/MyProjectTabs.module.css'; // Importing the CSS module
import { toastError, toastInfo } from '../Toast/ToastComponent';
import { ProjectApi } from '../../Api/ProjectApi';
import { useAuth } from '../../Context/AuthContext';
import { ContainerOutlined } from '@ant-design/icons';
import MyProjectTable from './MyProjectTable';
import Module from '../../Api/Module';
import NoDataFound from '../ReusableComponents/NoDataFound';
import { Input } from 'antd';

const { Search } = Input;

function MyProjectTabs() {
  const { authState } = useAuth();

  const [projectList, setProjectList] = useState([]);

  const [moduleList, setModuleList] = useState([]);

  const getProjects = async () => {
    try {
      const response = await ProjectApi.get({
        user_id: authState?.id,
        userRoleID: authState?.userRoleID,
      });

      if (response?.data?.success) {
        let projectList = response?.data?.result;
        setProjectList(projectList);
        projectList?.length && getModules(projectList[0].id);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const getModules = async (project) => {
    try {
      const response = await Module.get({
        user_id: authState?.id,
        userRoleID: authState?.userRoleID,
        isDraft: false,
        project_id: project,
      });
      if (response?.data?.success) {
        setModuleList(response?.data?.result);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div>
      <div className={projectList?.length >= 0 && style.myProjectTabs}>
        <div className="col-md-4 px-0">
          <h5 className={style.myProjectTitle}>My Projects</h5>
        </div>
      </div>
      {projectList?.length <= 0 ? (
        <NoDataFound />
      ) : (
        <div>
          <Row>
            <Col md={12}>
              <Card className={style.cardContainer}>
                {/* <div className="col-2 col-md-2 pe-1 px-0">
                  <Search
                    allowClear
                    //onChange={handleSearch}
                    placeholder="Search "
                  />
                </div> */}
                <div className="m-2">
                  <Tabs
                    tabBarStyle={{
                      fontSize: '25px',
                      fontWeight: 'bold',
                      backgroundColor: '#f0f0f0',
                      width: '200px',
                    }}
                    itemActiveColor="red"
                    itemSelectedColor="red"
                    onChange={(value) => getModules(value)}
                    tabPosition={'left'}
                    items={projectList.map((element, index) => ({
                      label: (
                        <span key={index} style={{ fontSize: '18px' }}>
                          {element.project_name}
                        </span>
                      ),
                      key: element?.id,
                      children: (
                        <div style={{ backgroundColor: 'red' }}>
                          <MyProjectTable moduleList={moduleList} />
                        </div>
                      ),
                    }))}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default MyProjectTabs;
