import { createSlice } from '@reduxjs/toolkit';
//month and year general settings

const setFilterLocal = () => {
  let getFilterLocal = sessionStorage.getItem('filterState');
  let parsed =
    getFilterLocal == undefined
      ? { month: '', year: '' }
      : JSON.parse(atob(getFilterLocal));
  return parsed;
};

let initialState =
  sessionStorage.getItem('filterState') == undefined
    ? {
        month: new Date().toLocaleString('en-US', {
          month: 'long',
        }),
        year: new Date().getFullYear(),
      }
    : setFilterLocal();

export const filterState = createSlice({
  name: 'filterState',
  initialState: {
    filter: initialState,
  },
  reducers: {
    setFilterMonth(state, action) {
      state.filter.month = action.payload;
    },
    setFilterYear(state, action) {
      state.filter.year = action.payload;
    },
  },
});

export const { setFilterMonth, setFilterYear } = filterState.actions;
export default filterState.reducer;
export const getfilterState = (state) => state?.filterState?.filter;
