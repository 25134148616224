import React, { useEffect, useState } from 'react';

import { Table } from 'reactstrap';
import { IoIosLock } from 'react-icons/io';
import style from '../../../Styles/TimeSheet.module.css';
import { FaCheckCircle } from 'react-icons/fa';
import TmPagination from '../../ReusableComponents/Pagination';
import { NORECORD } from '../../../constant/constant';
import { PaginationRange } from '../../../constant/constant';
import profilePhoto from '../../../Assets/profile.jpg';
import TmTableImage from '../../ReusableComponents/TmTableImage';

export const UserTimesheetTable = ({ columns, rows, handleClick }) => {
  const [pagination, setPagination] = useState([]);

  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };
  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };

  useEffect(() => {
    handlePagination(1, rows);
  }, [rows]);

  return (
    <div>
      <Table responsive className="table border">
        <thead>
          <tr>
            {columns.map((item) => (
              <th key={item.field} className={`${item.className}`}>
                {item.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((itm, index) => (
              <tr
                className={style.hoverTr}
                key={index}
                onClick={() => handleClick(itm)}
              >
                <td title={itm?.username}>
                  <div>
                    <TmTableImage
                      itm={itm?.username}
                      profilePicture={itm?.profilePicture}
                    />
                  </div>
                </td>
                {/* <td title={itm?.employeeCode}>{itm?.employeeCode || '--'}</td> */}
                <td title={itm?.department_name}>
                  {itm?.department_name || '--'}
                </td>
                <td style={{ minWidth: 180 }}>
                  {/* <span
                    className={`alert ${
                      itm?.pending_count && !itm?.rejected_count
                        ? 'alert-warning'
                        : itm?.rejected_count
                        ? 'alert-danger'
                        : 'alert-success'
                    } mb-0 py-0 px-2 rounded-pill`}
                  >
                    {`${
                      itm?.pending_count && !itm?.rejected_count
                        ? `Pending (${itm?.pending_count})`
                        : itm?.rejected_count
                        ? `Rejected (${itm?.rejected_count})`
                        : 'Approved'
                    } `}
                  </span> */}

                  <span className="fw-semibold">
                    <span title="Pending">
                      <span>P</span>&nbsp;-&nbsp;
                      <span
                        className="fw-bold"
                        style={{ color: 'rgb(255 191 0)' }}
                      >
                        {itm?.pending_count}
                      </span>
                      &nbsp;
                    </span>
                    &nbsp;| &nbsp;
                    <span title="Approved">
                      A&nbsp;-&nbsp;
                      <span
                        className="fw-bold"
                        style={{ color: 'rgb(104 181 146)' }}
                      >
                        {itm?.approved_count}
                      </span>
                      &nbsp;
                    </span>
                    &nbsp;| &nbsp;
                    <span title="Rejected">
                      R&nbsp;-&nbsp;
                      <span
                        className="fw-bold"
                        style={{ color: 'rgb(243 115 129)' }}
                      >
                        {itm?.rejected_count}
                      </span>
                      &nbsp;
                    </span>
                    &nbsp;
                  </span>
                </td>
                <td title={itm?.first_approvers_Monthstatus || '--'}>
                  <span
                    className={`alert ${
                      !itm?.first_approvers_Monthstatus
                        ? 'alert-warning'
                        : 'alert-success'
                    } mb-0 py-0 px-2 rounded-pill`}
                  >
                    {`${
                      !itm?.first_approvers_Monthstatus
                        ? `Pending `
                        : 'Approved'
                    } `}
                  </span>
                </td>
                <td title={itm?.second_approvers_Monthstatus || '--'}>
                  <span
                    className={`alert ${
                      !itm?.second_approvers_Monthstatus
                        ? 'alert-warning'
                        : 'alert-success'
                    } mb-0 py-0 px-2 rounded-pill`}
                  >
                    {`${
                      !itm?.second_approvers_Monthstatus
                        ? `Pending `
                        : 'Approved'
                    } `}
                  </span>
                </td>
                <td title={itm?.monthStatus || '--'}>
                  <span
                    className={`alert ${
                      !itm?.monthStatus ? 'alert-warning' : 'alert-success'
                    } mb-0 py-0 px-2 rounded-pill`}
                  >
                    {`${!itm?.monthStatus ? `Pending ` : 'Approved'} `}
                  </span>
                </td>
                {/* <td title={itm?.designation_name}>
                  {itm?.designation_name || '--'}
                </td> */}
                <td title={itm?.total_hours}>{itm?.total_hours}&nbsp;hrs</td>
                <td title={itm?.billable_hours}>
                  {itm?.billable_hours}&nbsp;hrs
                </td>
                <td title={itm?.non_billable_hours}>
                  {itm?.non_billable_hours}&nbsp;hrs
                </td>
                <td title={itm?.leave_count}>{itm?.leave_count}</td>
                <td title={itm?.permissions}>{itm?.permissions}&nbsp;hrs</td>
                <td title={itm?.first_approvers}>
                  {itm?.first_approvers || '--'}
                </td>
                <td title={itm?.second_approvers}>
                  {itm?.second_approvers || '--'}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="mt-2">
        <TmPagination
          total={rows.length}
          onShowSizeChange={onShowSizeChange}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};
