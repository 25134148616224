import React from 'react';
import MyProjectTabs from '../../Components/Project/MyProjectTabs';
function MyProjects() {
  return (
    <>
      <MyProjectTabs />
    </>
  );
}

export default MyProjects;
