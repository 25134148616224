import React, { Suspense, useEffect, useRef, useState } from 'react';
import ProjectCard from './ProjectCard';
import { Button, Switch } from 'antd';
import { MdAdd, MdGridView } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import TmButton from '../ReusableComponents/TmButton';
import { Col, Row } from 'reactstrap';
import style from '../../Styles/Project.module.css';

import { ProjectApi } from '../../Api/ProjectApi';
import { Input } from 'antd';
import Loader from '../ReusableComponents/Loader';
import {
  DeletePopup,
  projectCardDelete,
} from '../ReusableComponents/DeletePopup';
import ProjectTable from '../Project/ProjectTable';
import { toastError, toastSuccess, toastWarn } from '../Toast/ToastComponent';
import { useSelector } from 'react-redux';
import { getProjects } from '../../store/reducers/actionPermission';
import { useAuth } from '../../Context/AuthContext';

import {
  MODULE_STATUS,
  ACTIVE_STATUS,
  BILLING_METHODS,
} from '../../constant/constant';
import TmFilter from '../ReusableComponents/TmFilter';
import { RiTableLine } from 'react-icons/ri';
import Department from '../../Api/Department';
const { Search } = Input;

let filterValue = {
  module_status: '',
  project_id: '',
  status: true,
  search: '',
};

const columns = [
  { field: 'serialNo', headerName: 'S.NO', className: 'col-auto' },
  { field: 'project_name', headerName: 'PROJECT', className: 'col-2' },
  { field: 'department_name', headerName: 'DEPARTMENT', className: 'col-2' },
  { field: 'module_count', headerName: 'MODULE', className: 'col-2' },

  {
    field: 'totalEstimatedHours',
    headerName: 'ESTIMATE HOURS',
    className: 'col-2',
  },
  {
    field: 'project_members',
    headerName: 'ASSIGNED MEMBERS',
    className: 'col-3',
  },
  {
    field: 'totalBillableHours',
    headerName: 'BILLABLE HOURS',
    className: 'col-2',
  },
  {
    field: 'created_By_name',
    headerName: 'CREATED BY	',
    className: 'col-2',
  },
  {
    field: 'modified_By_Name',
    headerName: 'UPDATED BY	',
    className: 'col-2',
  },
  { field: 'action', headerName: 'ACTION', className: 'col-1' },
];

const ProjectComponent = () => {
  const [filterFeeds, setFilterFeeds] = useState({
    project: [],
    department: [],
  });

  const { view_type } = useParams();

  const [datas, setData] = useState([]);
  const debounceTimeoutRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);

  const navigate = useNavigate();

  const handleAddProject = () => {
    const determineView = view == true ? 'card' : ' table';
    navigate(`/add_Project/${btoa(determineView)}`);
    return;
  };

  const { authState } = useAuth();

  const data = useSelector(getProjects);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
    view: undefined,
  });

  useEffect(() => {
    const viewtype = view_type ? atob(view_type) : false;

    if (!viewtype || viewtype == 'table') {
      setView(false);
    } else if (viewtype == 'card') {
      setView(true);
    }
    getDropDown();
    getProject();

    if (data) {
      handleButtonPermission();
    }
    return () => {
      filterValue = {
        module_status: '',
        project_id: '',
        status: true,
        search: '',
      };
    };
  }, [data]);

  const handleFilterChange = (obj, type) => {
    // filterValue = { ...filterValue, [keys]: e };
    // filterValue = obj || {};
    filterValue = { ...filterValue, ...obj } || {};
    getProject();
  };

  let filterArray = [
    {
      options: filterFeeds.project,
      optionFilterProp: 'label',
      placeholder: 'Project',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'dropdown_search',
      allowClear: true,
      input: 'select',
    },

    {
      options: filterFeeds.department,
      optionFilterProp: 'label',
      placeholder: 'Department',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'department_name',
      allowClear: true,
      input: 'select',
    },
    {
      options: BILLING_METHODS,
      optionFilterProp: 'label',
      placeholder: 'Billing Method',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'billing_method',
      allowClear: true,
      input: 'select',
    },
    {
      options: ACTIVE_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: true,
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };
  const getDropDown = async () => {
    try {
      const response = await ProjectApi.dropDown({
        id: authState.id,
        userRoleID: authState?.userRoleID,
      });
      const department = await Department.dropDown({});
      if (response?.data?.success && department?.data?.success) {
        setFilterFeeds((pre) => ({
          ...pre,
          project: response.data?.result,
          department: department.data?.result,
        }));
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };
  const getProject = async () => {
    try {
      let response = await ProjectApi.get({
        user_id: authState?.id,
        userRoleID: authState?.userRoleID,
        ...filterValue,
      });
      if (response?.data?.success) {
        setData(response?.data?.result);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleClicks = async (id, status) => {
    await projectCardDelete(async (canDelete) => {
      if (canDelete) {
        try {
          let reqdata = {
            id: id,
          };
          let response = await ProjectApi.delete(reqdata);
          if (response.data.success) {
            toastSuccess(response.data.message);
            getProject();
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          toastError(error.response.data.message);
        }
      }
    }, status);
  };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };

  const handleSwitchChange = (value) => {
    setView(value);
  };

  return (
    <>
      <div>
        <div className="row mx-0">
          <div className="col-md-4 px-0">
            <h5 className="text-nowrap">Project</h5>
          </div>
          <div className="col-md-8 px-0">
            <div className="row justify-content-md-end align-items-center mx-0">
              <div className="col-10 col-md-auto pe-1 px-0">
                <Search
                  allowClear
                  onChange={handleSearch}
                  placeholder="Search "
                />
              </div>

              <div className="col-auto ms-0 px-0 antdBtn">
                {buttonPermission?.add && (
                  <Button
                    title="Add"
                    type="primary"
                    htmlType="button"
                    onClick={handleAddProject}
                    icon={<MdAdd size={20} />}
                  ></Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 mb-0">
          <TmFilter
            filterArray={filterArray}
            handleFilterChange={handleFilterChange}
          />
        </div>

        <div className="d-flex justify-content-end">
          <Button
            title="grid"
            className="me-1"
            onClick={() => setView(false)}
            icon={<RiTableLine size={20} />}
          ></Button>
          <Button
            title="card"
            onClick={() => setView(true)}
            icon={<MdGridView size={20} />}
          ></Button>
        </div>
        <div>
          {loading &&
            ((view === false && (
              <div className="">
                <ProjectTable
                  view_type={view}
                  columns={columns}
                  rows={datas}
                  handleClicks={handleClicks}
                  buttonPermission={buttonPermission}
                />
              </div>
            )) ||
              (view === true && (
                <ProjectCard
                  view_type={view}
                  handleClicks={handleClicks}
                  items={datas}
                  buttonPermission={buttonPermission}
                />
              )))}
          {!loading && <Loader />}
        </div>
      </div>
    </>
  );
};

export default ProjectComponent;
