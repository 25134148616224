import React from 'react';
import style from '../../Styles/MyProjectTabs.module.css';
import { Col, Row, Table } from 'reactstrap';
import { Tabs, Card } from 'antd';
import { MY_PROJECT_TABLE } from '../../constant/tableHeader';
import ShowStatus from '../ReusableComponents/ShowStatus';
import { NORECORD } from '../../constant/constant';

function MyProjectTable({ moduleList }) {
  return (
    <>
      <div className={style.tabContent}>
        <Card className={style.card}>
          <Table
            className={`${style.tableHeaderCustom} ${style.border}`}
            responsive
          >
            <thead>
              <tr>
                {MY_PROJECT_TABLE.map((item, index) => (
                  <th key={index} className={`${item.className} text-nowrap`}>
                    {item.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {moduleList?.length <= 0 && (
                <tr>
                  <td colSpan={100} className="text-center">
                    {NORECORD}
                  </td>
                </tr>
              )}

              {moduleList?.map((data, index) => (
                <tr key={index}>
                  <td>{data.serialNo}</td>
                  <td>{data.module_name}</td>
                  <td>{data.project_name}</td>
                  <td>{data.estimated_hours}</td>
                  <td>{<ShowStatus status={data.module_status} />}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </div>
    </>
  );
}

export default MyProjectTable;
